import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import CustomDrawer from "../../components/GeneralComponents/CustomDrawer";
import CustomMUITable from "../../components/GeneralComponents/CustomMUITable/CustomMUITable";
import { students_all_reports_list_api } from "../../DAL/StudentReports/StudentReports";
import moment from "moment";
import ReportsFilter from "../../components/StudentReports/ReportsFilter";
import { Icon } from "@iconify/react";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
import { useContentSetting } from "../../Hooks/ContentSetting";

const EMPTY_FILTER = {
  lesson_book: "all",
  start_date: new Date(new Date().setDate(new Date().getDate() - 7)),
  end_date: new Date(),
  search_text: "",
};

export default function StudentReports() {
  const { enqueueSnackbar } = useSnackbar();
  const { reportsList, setReportsList } = useContentSetting();
  const [isLoading, setIsLoading] = useState(true);
  const [filterData, setFilterData] = useState(EMPTY_FILTER);
  const [filterDrawer, setFilterDrawer] = useState(false);

  const handleOpenFilterDrawer = () => {
    setFilterDrawer(true);
  };
  const handleCloseFilterDrawer = () => {
    setFilterDrawer(false);
  };

  const get_reports_list = async (data) => {
    setIsLoading(true);
    let postData = { ...data };
    postData.start_date = moment(postData.start_date).format("YYYY-MM-DD");
    postData.end_date = moment(postData.end_date)
      .add(1, "days")
      .format("YYYY-MM-DD");

    const result = await students_all_reports_list_api(postData);
    if (result.code === 200) {
      const data = result.reports_list.map((report) => {
        let report_date = moment(report.report_date_time).format(
          "DD-MM-YYYY hh:mm A"
        );
        return {
          ...report,
          student_name: report.student?.name,
          report_date: report_date,
          quran_part_mumber: report.part_mumber ? report.part_mumber : "",
          quran_verse_number: report.verse_number ? report.verse_number : "",
          lesson_book_name: `${
            report.lesson_book === "quran"
              ? "Holy Quran"
              : report.lesson_book === "madani_quaida"
              ? "Madani Quadia"
              : "Noorani Quadia"
          } `,
        };
      });
      setReportsList(data);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    { id: "report_date", label: "Report Date" },
    { id: "student_name", label: "Student Name" },
    { id: "lesson_book_name", label: "Lesson Book" },
    {
      id: "quran_part_mumber",
      label: "Part Number",
      className: "text-center",
    },
    {
      id: "page_number",
      label: "Page Number",
      className: "text-center",
    },
    {
      id: "line_number",
      label: "Line Number",
      className: "text-center",
    },
    {
      id: "quran_verse_number",
      label: "Verse Number",
      className: "text-center",
    },
  ];

  const handleChange = (name, value) => {
    setFilterData((old) => {
      return { ...old, [name]: value };
    });
  };

  const searchFunction = () => {
    handleCloseFilterDrawer();
    get_reports_list(filterData);
  };

  const handleClearFilter = (e) => {
    e.preventDefault();
    setFilterData(EMPTY_FILTER);
    handleCloseFilterDrawer();
    get_reports_list(EMPTY_FILTER);
  };

  useEffect(() => {
    get_reports_list(EMPTY_FILTER);
  }, []);

  if (isLoading) {
    return (
      <div className="circular-progress">
        <CircularProgress color="primary" />
      </div>
    );
  }

  return (
    <>
      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col-12">
            <div className="theme-card p-3">
              <div className="d-flex flex-column flex-md-row justify-content-between light-border-bottom">
                <div className="mb-2 mui-table-heading">
                  <h2>Student Reports List</h2>
                </div>
                <div className="add--button mb-3 text-end">
                  <button className="me-2" onClick={handleOpenFilterDrawer}>
                    Filters &nbsp;&nbsp; <Icon icon={roundFilterList} />
                  </button>
                </div>
              </div>
              <CustomMUITable TABLE_HEAD={TABLE_HEAD} data={reportsList} />
            </div>
          </div>
        </div>
      </div>
      <CustomDrawer
        isOpenDrawer={filterDrawer}
        onCloseDrawer={handleCloseFilterDrawer}
        pageTitle="Student Filter"
        componentToPassDown={
          <ReportsFilter
            filterData={filterData}
            handleChange={handleChange}
            handleSubmit={searchFunction}
            handleClearFilter={handleClearFilter}
          />
        }
      />
    </>
  );
}
