import React, { useState, useRef, useEffect } from "react";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import moment from "moment";
import { CircularProgress, Tooltip } from "@mui/material";
import { s3baseUrl } from "../../config/config";

export default function ReactAudioPlayer(props) {
  const { audio_url, duration } = props;
  const [totalDuration, setTotalDuration] = useState(0);
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isEnded, setIsEnded] = useState(false);
  const [isReady, setIsReady] = useState(false);

  const handleAudioEnd = () => {
    setIsEnded(true);
    setIsPlaying(false);
    setTotalDuration(duration);
  };

  const handleAudioReady = () => {
    setIsReady(true);
  };

  const togglePlayPause = () => {
    if (isEnded) {
      setTotalDuration(0);
    }
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      setIsEnded(false);
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  useEffect(() => {
    let interval;
    if (isPlaying) {
      interval = setInterval(() => {
        setTotalDuration((prevTimer) => prevTimer + 100);
      }, 100);
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [isPlaying]);

  let recorded_with = (totalDuration / duration) * 100;

  return (
    <div className="react-audio-player">
      <audio
        ref={audioRef}
        src={s3baseUrl + audio_url}
        onEnded={handleAudioEnd}
        onLoadedData={handleAudioReady}
      />
      <div className="custom-audio-player">
        <div onClick={togglePlayPause}>
          {!isReady ? (
            <CircularProgress className="audio-loader" />
          ) : isPlaying ? (
            <Tooltip title="Pause">
              <PauseIcon />
            </Tooltip>
          ) : (
            <Tooltip title="Play">
              <PlayArrowIcon />
            </Tooltip>
          )}
        </div>
        <div className="full-player">
          <div
            className="played-audio"
            style={{
              width: `${recorded_with}%`,
            }}
          ></div>
        </div>
        <div className="recorded-time">
          {moment.utc(totalDuration ? totalDuration : duration).format("mm:ss")}
        </div>
      </div>
    </div>
  );
}
