const get_icon = (name) => {
  return <i className={`nav-icon ${name}`}></i>;
};

const sidebarConfig = () => {
  let sidebarMenus = [
    {
      title: "Dashboard",
      path: "/dashboard",
      icon: get_icon("fas fa-tachometer-alt"),
    },
    {
      title: "Students Reports",
      path: "/students-reports",
      icon: get_icon("fa-solid fa-list"),
    },
    // {
    //   title: "Class Room",
    //   path: "/class-room",
    //   icon: get_icon("fas fa-users"),
    // },
    {
      title: "Students",
      path: "/students",
      icon: get_icon("fas fa-user-graduate"),
    },
    {
      title: "Personal Notes",
      path: "/personal-notes",
      icon: get_icon("fa-solid fa-book"),
    },
    {
      title: "To Do Lists",
      path: "/to-do-lists",
      icon: get_icon("fa-solid fa-list"),
    },
    {
      title: "Fees Management",
      path: "/fees-management",
      icon: get_icon("fa-solid fa-comment-dollar"),
    },
    // {
    //   title: "Announcements",
    //   path: "/announcements",
    //   icon: get_icon("fas fa-bullhorn"),
    // },
  ];
  return sidebarMenus;
};
export default sidebarConfig;
