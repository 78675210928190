import React from "react";
import { currency_sign, show_proper_words } from "../../utils/constant";

export default function StudentsTable(props) {
  const { students, currency, payment_charges, total_amount, month, year } =
    props;

  return (
    <table className="fees-info-table w-100">
      <thead>
        <tr>
          <td>Student Name</td>
          {month && <td>Month</td>}
          {year && <td>Year</td>}
          <td>Payable Fee</td>
        </tr>
      </thead>
      <tbody>
        {students.map((student) => {
          return (
            <tr key={student._id}>
              <td>{student.name}</td>
              {month && <td>{show_proper_words(month)}</td>}
              {year && <td>{year}</td>}
              <td>{currency_sign(currency) + student.fee_amount}</td>
            </tr>
          );
        })}

        <tr>
          <td className="total-fee-text" colSpan={month ? 3 : 1}>
            Sub Total
          </td>
          <td className="total-fee-text">
            {currency_sign(currency) + total_amount}
          </td>
        </tr>
        <tr>
          <td colSpan={month ? 3 : 1}>Payment Charges (2.9%) </td>
          <td>{currency_sign(currency) + payment_charges.toFixed(2)}</td>
        </tr>
        <tr>
          <td className="total-fee-text" colSpan={month ? 3 : 1}>
            Total Fee
          </td>
          <td className="total-fee-text">
            {currency_sign(currency) +
              (total_amount + payment_charges).toFixed(2)}
          </td>
        </tr>
      </tbody>
    </table>
  );
}
