import { invokeApi } from "../../bl_libs/invokeApi";

export const fees_management_list_api = async () => {
  const requestObj = {
    path: `api/fees_management/fee_requests_for_client`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const fees_request_detail_api = async (request_id) => {
  const requestObj = {
    path: `api/fees_management/${request_id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const get_intent_client_secret = async (data) => {
  const requestObj = {
    path: `api/fees_management/create_payment_intent`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const confirm_stripe_payment_api = async (request_id) => {
  const requestObj = {
    path: `api/fees_management/confirm_stripe_payment/${request_id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
