import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import CustomMUITable from "../../components/GeneralComponents/CustomMUITable/CustomMUITable";
import { s3baseUrl } from "../../config/config";
import { students_list_api } from "../../DAL/Students/Students";
import moment from "moment/moment";
import { useContentSetting } from "../../Hooks/ContentSetting";
import { currency_sign } from "../../utils/constant";

export default function StudentsList() {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [studentsList, setStudentsList] = useState([]);
  const { userInfo } = useContentSetting();

  const get_students_list = async () => {
    const result = await students_list_api();
    if (result.code === 200) {
      const data = result.students_list.map((student) => {
        let feeAmount = currency_sign(userInfo.currency) + student.fee_amount;
        let birthDate = moment(student.birth_date, "YYYY-MM-DD").format(
          "DD-MM-YYYY"
        );
        return {
          ...student,
          table_avatar: {
            src: s3baseUrl + student.profile_image,
            alt: student.name,
          },
          birthDate,
          feeAmount,
          lesson_book_name: `${
            student.lesson_book === "quran"
              ? "Holy Quran"
              : student.lesson_book === "madani_quaida"
              ? "Madani Quadia"
              : "Noorani Quadia"
          } `,
        };
      });
      setStudentsList(data);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    { id: "roll_number_string", label: "Student ID" },
    { id: "name", label: "Name" },
    {
      id: "gender",
      label: "Gender",
      className: "text-capitalize",
    },
    { id: "feeAmount", label: "Fee Amount" },
    { id: "lesson_book_name", label: "Lesson Book" },
    { id: "birthDate", label: "Birth Date" },
    {
      id: "table_avatar",
      label: "Profile",
      type: "thumbnail",
      className: "reduce-cell-padding",
    },
  ];

  useEffect(() => {
    get_students_list();
  }, []);

  if (isLoading) {
    return (
      <div className="circular-progress">
        <CircularProgress color="primary" />
      </div>
    );
  }

  return (
    <>
      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col-12">
            <div className="theme-card p-3">
              <div className="d-flex justify-content-between light-border-bottom">
                <div className="mb-2 mui-table-heading">
                  <h2>Students List</h2>
                </div>
              </div>
              <CustomMUITable TABLE_HEAD={TABLE_HEAD} data={studentsList} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
