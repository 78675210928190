import { invokeApi } from "../../bl_libs/invokeApi";

export const students_all_reports_list_api = async (data) => {
  const requestObj = {
    path: `api/clients/students_reports_list`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
