import React, { useState } from "react";
import { useSnackbar } from "notistack";
import {
  confirm_stripe_payment_api,
  get_intent_client_secret,
} from "../../DAL/FeesManagement/FeesManagement";
import { useStripe, CardElement, useElements } from "@stripe/react-stripe-js";
import StudentsTable from "./StudentsTable";
import { useNavigate } from "react-router-dom";

export default function PayByStripe({ feeInfo }) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(false);
  const [textColor] = useState(
    getComputedStyle(document.documentElement).getPropertyValue(
      "--text-primary-color"
    )
  );

  const handleSecureCard = (client_secret, cardElement) => {
    enqueueSnackbar("Processing card...", {
      variant: "info",
    });

    stripe
      .confirmCardPayment(client_secret, {
        payment_method: {
          card: cardElement,
        },
      })
      .then(async function (result) {
        if (result.error) {
          setIsLoading(false);
          enqueueSnackbar(result.error.message, { variant: "error" });
          return;
        }
        let fee_result = await confirm_stripe_payment_api(feeInfo._id);
        if (fee_result.code === 200) {
          enqueueSnackbar(fee_result.message, { variant: "success" });
          setIsLoading(false);
          navigate(`/fees-management`);
        } else {
          enqueueSnackbar(fee_result.message, { variant: "error" });
          setIsLoading(false);
        }
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: "error" });
      });
  };

  const getIntentClientSecret = async (cardElement, postData) => {
    const result = await get_intent_client_secret(postData);
    if (result.code === 200) {
      handleSecureCard(result.client_secret, cardElement);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (elements == null) {
      return;
    }
    setIsLoading(true);
    const cardElement = elements.getElement(CardElement);
    stripe.createToken(cardElement).then(function (result) {
      if (result.error) {
        setIsLoading(false);
        enqueueSnackbar(result.error.message, { variant: "error" });
        return;
      }
      let postData = {
        request_id: feeInfo._id,
      };
      getIntentClientSecret(cardElement, postData);
    });
  };

  return (
    <>
      <div className="container new-memories">
        <form onSubmit={handleSubmit}>
          <div className="row input-form">
            {feeInfo.students?.length > 0 && (
              <div className="col-12 mt-3">
                <StudentsTable
                  students={feeInfo.students}
                  currency={feeInfo.currency}
                  total_amount={feeInfo.amount}
                  month={feeInfo.month}
                  year={feeInfo.year}
                  payment_charges={feeInfo.payment_charges}
                />
              </div>
            )}

            <div className="col-12 mt-4">
              <div className="custom-card-element">
                <CardElement
                  options={{
                    hidePostalCode: true,
                    style: {
                      base: {
                        iconColor: textColor, // 🔥
                        color: textColor, // 🔥
                        "::placeholder": {
                          color: textColor, // 🔥
                        },
                        fontSize: "18px",
                      },
                    },
                  }}
                />
              </div>
            </div>
          </div>

          <div className="col-12 add--button text-end mt-3">
            <button disabled={isLoading}>
              {isLoading ? "Please wait..." : "Pay Now"}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
