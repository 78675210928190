import React, { useEffect, useState } from "react";
import PayByStripe from "../../components/FeesManagement/PayByStripe";
import { Link, useParams } from "react-router-dom";
import { fees_request_detail_api } from "../../DAL/FeesManagement/FeesManagement";
import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { currency_sign } from "../../utils/constant";

export default function PayNow() {
  const { request_id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [feeInfo, setFeeInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [publishKey, setPublishKey] = useState("");

  const get_fee_info = async () => {
    const result = await fees_request_detail_api(request_id);
    if (result.code === 200) {
      setFeeInfo(result.fee_request);
      setPublishKey(result.publish_key);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    get_fee_info();
  }, []);

  if (isLoading) {
    return (
      <div className="circular-progress">
        <CircularProgress color="primary" />
      </div>
    );
  }

  return (
    <div className="theme-card p-3 mt-3">
      <div className="back-button-title">
        <div className="back-button-box">
          <Link to={`/fees-management`}>
            <i className="fas fa-arrow-left"></i>
          </Link>
        </div>
        <div className="table-title">{`Pay now an amount of ${
          currency_sign(feeInfo.currency) + feeInfo.amount
        }`}</div>
      </div>
      <Elements stripe={loadStripe(publishKey)}>
        <PayByStripe feeInfo={feeInfo} />
      </Elements>
    </div>
  );
}
