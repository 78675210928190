import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import CustomMUITable from "../../components/GeneralComponents/CustomMUITable/CustomMUITable";
import { fees_management_list_api } from "../../DAL/FeesManagement/FeesManagement";
import { s3baseUrl } from "../../config/config";
import { currency_sign, show_proper_words } from "../../utils/constant";
import StudentsTable from "../../components/FeesManagement/StudentsTable";
import ComponentPopup from "../../components/GeneralComponents/ComponentPopup";
import { useNavigate } from "react-router-dom";

export default function FeesManagement() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [feesList, setFeesList] = useState([]);
  const [openDetail, setOpenDetail] = useState(false);
  const [selectedObject, setSelectedObject] = useState({});

  const handleCloseDetail = () => {
    setOpenDetail(false);
    setSelectedObject();
  };

  const get_fees_list = async () => {
    const result = await fees_management_list_api();
    if (result.code === 200) {
      const data = result.fee_requests.map((request) => {
        let guardian_name = "";
        if (request.created_for?.name) {
          let guardian_email = request.created_for.email;
          guardian_name =
            request.created_for.name + " (" + guardian_email + ")";
        }

        let table_avatar = {
          src: s3baseUrl + request.receipt_slip,
          alt: "Receipt Slip",
        };
        return { ...request, table_avatar, guardian_name };
      });
      setFeesList(data);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleClick = (row) => {
    setSelectedObject(row);
    setOpenDetail(true);
  };

  const handlePay = (request) => {
    navigate(`/fees-management/${request._id}`);
  };

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    {
      label: "Total Fee",
      renderData: (row) => (
        <div onClick={() => handleClick(row)} className="fees-list-label">
          {currency_sign(row.currency) + row.amount.toFixed(2)}
        </div>
      ),
    },
    {
      label: "Payment Charges(2.9%)",
      renderData: (row) => (
        <div>
          {currency_sign(row.currency) + row.payment_charges.toFixed(2)}
        </div>
      ),
    },
    {
      label: "Total Amount",
      renderData: (row) => (
        <div>
          {currency_sign(row.currency) +
            (row.amount + row.payment_charges).toFixed(2)}
        </div>
      ),
    },
    {
      id: "month",
      label: "Month",
      className: "text-capitalize",
    },
    {
      id: "year",
      label: "Year",
    },
    {
      id: "fee_status",
      label: "Fee Status",
      renderData: (row) => {
        let fee_status = row.fee_status;
        let class_name = "";
        if (fee_status === "pending") {
          class_name = "bg-danger";
        } else if (fee_status === "submitted") {
          class_name = "bg-primary";
        }
        return (
          <span className={`fee-status ${class_name}`}>
            {show_proper_words(fee_status)}
          </span>
        );
      },
    },
    {
      id: "table_avatar",
      label: "Receipt Slip",
      type: "thumbnail",
      show_online: true,
      className: "reduce-cell-padding",
    },
    {
      label: "Pay Now",
      renderData: (row) => {
        if (row.fee_status === "received") return;
        return (
          <button className="pay-now-button" onClick={() => handlePay(row)}>
            Pay Now
          </button>
        );
      },
    },
  ];

  useEffect(() => {
    get_fees_list();
  }, []);

  if (isLoading) {
    return (
      <div className="circular-progress">
        <CircularProgress color="primary" />
      </div>
    );
  }

  const detail_table = {
    students: selectedObject.students,
    currency: selectedObject.currency,
    payment_charges: selectedObject.payment_charges,
    total_amount: selectedObject.amount,
  };

  return (
    <>
      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col-12">
            <div className="theme-card p-3">
              <div className="d-flex flex-column flex-md-row justify-content-between light-border-bottom">
                <div className="mb-2 mui-table-heading">
                  <h2>Fees Management</h2>
                </div>
              </div>
              <CustomMUITable TABLE_HEAD={TABLE_HEAD} data={feesList} />
            </div>
          </div>
        </div>
      </div>
      <ComponentPopup
        openPopup={openDetail}
        setOpenPopup={setOpenDetail}
        title="Fee Detail"
        paperClass="fees-detail-popup"
        componentToPassDown={
          <StudentsTable handleClose={handleCloseDetail} {...detail_table} />
        }
      />
    </>
  );
}
